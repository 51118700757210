import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"

export const PrivacyPolicyTemplate = ({ content, contentComponent }) => {
  const PostContent = contentComponent || Content

  return <PostContent content={content} />
}

PrivacyPolicyTemplate.propTypes = {
  content: PropTypes.node.isRequired,
}

const PrivacyPolicy = ({ data, path }) => {
  const { markdownRemark } = data

  return (
    <Layout loadMap={false}>
      <SEO
        path={path}
        title="Privacy Policy - EstatesPlus - Wills, Probate & Letters of Administration. Save up to 90% on legal fees"
        description="Privacy Policy This Privacy Policy applies to all personal information collected by MKI Legal Pty Ltd & Legal Check Pty Ltd (Us) via this website, through Facebook and Google. MKI Legal is an Australian law firm owned by MKI Legal Pty Ltd. This website is owned and operated by Legal Check Pty Ltd and licensed"
      />
      <div className="container section-gap">
        <PrivacyPolicyTemplate
          contentComponent={HTMLContent}
          content={markdownRemark.html}
        />
      </div>
    </Layout>
  )
}

PrivacyPolicy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-policy" } }) {
      html
    }
  }
`
